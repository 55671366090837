/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 64.4.5-v202501091609-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqNotifierApiClass {
 
   constructor() {}

  /**
   * @summary Returns the configuration values that may be relevant for using the emailer service.
   * @param {string} [accept] - API version
   */
  public getConfiguration(
    {
      accept
    } : {
      accept?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/notify/configuration`,

      headers: {
        ['Accept']: accept as string
      },
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.EmailerConfigurationOutputV1>;
  }

  /**
   * @summary Sends an email.
   * @param {string} [accept] - API version
   */
  public sendEmail(
    body: models.SendEmailInputV1,
    {
      accept
    } : {
      accept?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/notify/send-email`,
      data: body,

      headers: {
        ['Accept']: accept as string
      },
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessage>;
  }

}


export const sqNotifierApi = new sqNotifierApiClass();
